
export interface OidcConfiguration {
    client_id: string;
    client_secret: string;
    redirect_uri: string;
    silent_redirect_uri: string;
    scope: string;
    authority: string;
}
  
const oidcConfiguration: OidcConfiguration = {
    client_id: "6943ccb6-b8d8-45f0-a3c8-2f846bae24a8",
    client_secret: "secret",
    redirect_uri: "https://ca-gmscloud-webapps-ncus-sb.grayocean-2baf75d8.northcentralus.azurecontainerapps.io/callback", 
    silent_redirect_uri: "https://ca-gmscloud-webapps-ncus-sb.grayocean-2baf75d8.northcentralus.azurecontainerapps.io/silent-callback",
    scope: "openid",
    authority: "https://thegmssandbox.b2clogin.com/thegmssandbox.onmicrosoft.com/B2C_1_signin_only_nomfa"
};

export const ConfigUrl = {
    baseURL: "https://ca-gmscloud-tenant-api-ncus-sb.grayocean-2baf75d8.northcentralus.azurecontainerapps.io/gms-provider/api/v2"
};
  
  export default oidcConfiguration;
  